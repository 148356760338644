import React from "react";
import Respondent from "../containers/Respondent/Respondent";
import RespondentQuestions from "../containers/Respondent/RespondentQuestions";
import RespondentBenefits from "../containers/Respondent/RespondentBenefits";
import HeaderMenu from "../containers/Institute/HeaderMenu";
import Footer from "../containers/Institute/Footer";
import GlobalStyle from "../containers/Institute/Common/global-styles";
import "../components/layout.css";
import Seo from "../components/seo";
import Fonts from "../containers/Institute/Common/fonts"

const Institute = () => (
   <div>
       <Fonts />
       <GlobalStyle />
       <HeaderMenu/>
       <main>
            <Seo
                title="Bluesurvey | Respondentes"
                description="Pesquise com rapidez e qualidade"
            />

            <Respondent/>
            <RespondentQuestions/>
            <RespondentBenefits/>
            
        </main>
       <Footer/>
   </div>

)

export default Institute;